.App {
  display: flex;
  flex-direction: column;
  background-color: #0f3138;
}

.appHeader {
  display: flex;
  flex-direction: row;
  height: 68px;
  background-color: #1b1d21;
  justify-content: space-between;
}

button.logo {
  margin-left: 12px;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 68px);
  background-color: white;
}

.dashboardContainer iframe {
  flex-grow: 1;
  border: none;
}

.dashboardContainer {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}

.dashboardContainer footer {
  padding: 1rem;
  background-color: azure;
  border-top: 1px solid gray;
  height: 50px;
  text-align: center;
}

.main {
  padding: 10px;
}
