.appHeader {
  background-color: #0f3138;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.logo {
  height: 24px;
  cursor: pointer;
}

.appHeader div {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 160px;
}

.dashboardContainer iframe {
  flex-grow: 1;
  border: none;
}

.dashboardContainer {
  display: flex;
  height: calc(100vh - 143px);
}

.dashboardContainer footer {
  padding: 1rem;
  background-color: azure;
  border-top: 1px solid gray;
  height: 50px;
  text-align: center;
}

.headingFont {
  font-family: "Inter";
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
  margin: 11px 0;
  color: #191924;
}

.tabs {
  display: flex;
  color: #515862;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-top: 13px;
}

.tabs div {
  cursor: pointer;
  padding: 0 12px 8px;
  border-bottom: solid 4px #c3f2d4;
}

.tabs div.selectedTab {
  color: black;
  border-bottom-color: #0f3138;
}

.lastTab {
  flex-grow: 1;
}

.groupBy {
  margin-top: -10px;
  margin-left: 10px !important;
}

.overview {
  text-align: center;
  margin-top: 20px;
}

.notAllowed {
  cursor: not-allowed;
}
